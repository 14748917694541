import React, { useCallback } from 'react';
import { PreviewArticleType } from '../../utils/blogTypes';
import ArticlePreview from '../ArticlePreview';
import './style.scss';

type MoreArticlesProps = {
  articles: PreviewArticleType[];
  className?: string;
};

const MoreArticles: React.FC<MoreArticlesProps> = ({ articles, className }) => {
  const renderArticle = useCallback<
    (article: PreviewArticleType) => JSX.Element
  >(
    article => (
      <ArticlePreview {...article} className="more-articles__article-item" />
    ),
    []
  );

  return articles.length ? (
    <div className={`more-articles ${className}`}>
      {articles.map(renderArticle)}
    </div>
  ) : (
    <></>
  );
};

export default React.memo(MoreArticles);
