import * as React from 'react';
import { SVGProps, memo } from 'react';

const ChevronLeft = ({
  pathClassName,
  ...props
}: SVGProps<SVGSVGElement> & { pathClassName?: string }) => (
  <svg
    width={9}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 13 2 7l6-6"
      stroke="#13171E"
      className={pathClassName}
      strokeWidth={1.5}
    />
  </svg>
);

export default memo(ChevronLeft);
