import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useCallback, useMemo, useState } from 'react';
import Footer from '../Footer';
import Navigation from '../Navigation';
import './style.scss';
import {
  AuthorType,
  PreviewArticleType,
  SocialIconProps,
} from '../../utils/blogTypes';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Text from '../Text';
import Markdown from '../Markdown';
import MoreArticles from '../MoreArticles';
import Button from '../Button';
import BlogBackButton from '../BlogBackButton';
import SmallFacebookIcon from '../icons/SmallFacebookIcon';
import SmallLinkedinIcon from '../icons/SmallLinkedinIcon';
import SmallTwitterIcon from '../icons/SmallTwitterIcon';
import SEO from '../seo';

type DataProps = {
  sanityAuthor: AuthorType;
  allSanityArticle: { nodes: PreviewArticleType[] };
};

type AuthorSocialIconProps = {
  Icon: React.FC<SocialIconProps>;
  link: string;
};

export const renderAuthorSocialIcons = ({
  link,
  Icon,
}: AuthorSocialIconProps): JSX.Element => {
  return (
    <a
      className={'author-social-icon'}
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <Icon
        parentClassName="author-social-icon"
        pathClassName="author-social-icon__path"
        circleClassName="author-social-icon__circle"
      />
    </a>
  );
};

export const getAuthorSocialData = ({
  facebook,
  twitter,
  linkedin,
}: {
  facebook?: string;
  twitter?: string;
  linkedin?: string;
}): AuthorSocialIconProps[] | undefined => {
  const facebookLink = !!facebook && {
    Icon: SmallFacebookIcon,
    link: facebook,
  };

  const linkedinLink = !!linkedin && {
    Icon: SmallLinkedinIcon,
    link: linkedin,
  };

  const twitterLink = !!twitter && {
    Icon: SmallTwitterIcon,
    link: twitter,
  };

  return [
    ...(facebookLink ? [facebookLink] : []),
    ...(linkedinLink ? [linkedinLink] : []),
    ...(twitterLink ? [twitterLink] : []),
  ];
};

const INITIAL_LATEST_ARTICLES = 9;

const AuthorPage: React.FC<{
  data: DataProps;
}> = ({
  data: {
    allSanityArticle,
    sanityAuthor: {
      name,
      title: jobTitle,
      image: rawImage,
      description,
      twitter,
      linkedin,
      facebook,
    },
  },
}) => {
  const [articlesExpanded, setArticlesExpanded] = useState(false);

  const viewAll = useCallback(() => setArticlesExpanded(true), []);

  const { t } = useTranslation();

  const image = getImage(rawImage.asset)!;

  const firstName = name.split(' ')[0];

  const backButtonText = t('article').toUpperCase();

  const authorSocialData = useMemo(
    () => getAuthorSocialData({ facebook, twitter, linkedin }),
    [facebook, linkedin, twitter]
  );

  const shouldShowSocials = !!authorSocialData?.length;

  const Socials = useMemo(() => {
    if (!shouldShowSocials) {
      return <></>;
    }

    return (
      <div className="author-page__socials">
        <Text
          type="p"
          weight="demi-bold"
          color="dark"
          className="author-page__socials-title"
        >
          {t('author_page_socials', { firstName })}
        </Text>

        <div className="author-page__socials-box">
          {authorSocialData?.map(renderAuthorSocialIcons)}
        </div>
      </div>
    );
  }, [authorSocialData, firstName, shouldShowSocials, t]);

  const LatestArticles = useMemo(() => {
    if (!allSanityArticle.nodes.length) {
      return <></>;
    }

    const allArticles = allSanityArticle.nodes;
    const initialArticles = allArticles.slice(0, INITIAL_LATEST_ARTICLES);

    const shouldShowButton =
      !articlesExpanded && allArticles.length > INITIAL_LATEST_ARTICLES;
    const articlesList = articlesExpanded ? allArticles : initialArticles;

    return (
      <div className="author-page__more-articles">
        <div className="author-page__more-articles-header">
          <Text
            type="h1"
            weight="bold"
            color="dark"
            className="author-page__more-articles-title"
          >
            {t('author_page_articles', { firstName })}
          </Text>

          {shouldShowButton && (
            <Button outlined color="transparent" size="small" onClick={viewAll}>
              {t('author_page_show_all')}
            </Button>
          )}
        </div>

        <MoreArticles
          className="author-page__more-articles-content"
          articles={articlesList}
        />
      </div>
    );
  }, [allSanityArticle.nodes, articlesExpanded, firstName, t, viewAll]);

  return (
    <div className="author-page">
      <Navigation headerBackgroundColor="none" />

      <div className="author-page__header">
        <BlogBackButton
          className="author-page__back"
          color="light"
          buttonText={backButtonText}
        />

        <div className="author-page__main-info">
          <GatsbyImage
            image={image}
            alt={name}
            className="author-page__avatar"
          />

          <div className="author-page__name-box">
            <Text
              type="h4"
              weight="demi-bold"
              color="light"
              className="author-page__name"
            >
              {name}
            </Text>

            <Text type="p" color="light" className="author-page__job-title">
              {jobTitle}
            </Text>
          </div>
        </div>
      </div>

      <div className="author-page__body">
        <div className="author-page__bio">
          {Socials}

          <div
            className={
              shouldShowSocials
                ? 'author-page__description-box'
                : 'author-page__description-box_expanded'
            }
          >
            <Markdown
              className="author-page__description"
              source={description ?? ''}
            />
          </div>
        </div>

        {description ? (
          <div className="dividing-line" />
        ) : (
          <div className="author-page__dividing-offset" />
        )}

        {LatestArticles}
      </div>

      <Footer noFooterCard={false} />
    </div>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!) {
    allSanityArticle(
      filter: { author: { elemMatch: { id: { eq: $id } } } }
      sort: { order: DESC, fields: _updatedAt }
    ) {
      nodes {
        ...ListArticle
        headerImage {
          mainImage {
            asset {
              gatsbyImageData(
                height: 210
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          altText
          caption
        }
      }
    }
    sanityAuthor(id: { eq: $id }) {
      title
      name
      description
      twitter
      facebook
      linkedin
      image {
        asset {
          gatsbyImageData(
            width: 160
            height: 160
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
          )
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default React.memo(AuthorPage);

export const Head: React.FC<{
  data: DataProps;
}> = ({
  data: {
    sanityAuthor: { name },
  },
}) => {
  return (
    <SEO
      title={`${name} Author's Page | Stormotion`}
      description={`${name} publications on Stormotion's blog. Click to read.`}
    />
  );
};
