export enum Tag {
  CaseStudy = 'case-study',
  ProductStrategy = 'product-strategy',
  Development = 'development',
  Tech = 'tech',
}

export type TextBlockType = {
  text: string;
};

export type BlockQuoteBoxType = {
  body: string;
  author?: string;
  image: {
    asset: any;
  };
};

export type CaptionedImageBoxType = {
  mainImage: {
    asset: any;
  };
  caption?: string;
  altText?: string;
  imageLink?: string;
  imageHeight?: number;
  imageWidth?: number;
};

export type CallToActionBoxType = {
  body: string;
  buttonText: string;
  buttonLink: string;
  backgroundColor: string;
  contentColor: string;
};

export enum CardIconType {
  MEDAL = 'MEDAL',
  SAD_FACE = 'SAD_FACE',
  HAPPY_FACE = 'HAPPY_FACE',
}

export type CardType = {
  title?: string;
  body: string;
  iconType: CardIconType;
  iconColor: string;
};

export type CardsBoxType = {
  cardsWithMedals: CardType[];
};

export type InfoBoxType = {
  body: string;
  outlineColor: string;
};

export type ReadAlsoBoxType = {
  title: string;
  body: string;
  link: string;
  backgroundColor: string;
};

export type TableCellType = {
  _id: string;
  body: string;
  rowspan: number;
  colspan: number;
  backgroundColor?: string;
};

export type TableRow = {
  _id: string;
  isHeader?: boolean;
  items: TableCellType[];
};

export type TableBoxType = {
  rows: TableRow[];
  headerText?: string;
};

export type SideNoteType = {
  body: string;
  buttonText: string;
  buttonLink: string;
};

export type EmbeddedBoxType = {
  link: string;
  width: number;
  height: number;
  style?: string;
  allow?: string;
  id: string;
};

export type ButtonBoxType = {
  backgroundColor: string;
  contentColor: string;
  text: string;
  link: string;
};

export type SnippetBoxType = {
  body: string;
  language: string;
};

export type ExcoBoxType = {
  excoId: string;
};

export type GoogleTrendsBoxType = {
  options: string;
  scriptUrl: string;
  keywords: string;
};

export type TradingViewEmbedType = {
  widgetOptions: string;
  embedId: string;
  chartUrl: string;
  chartName: string;
};

export type CheckliEmbedBoxType = {
  embedId: string;
  embedUrl: string;
};

export type ArticleFaqType = {
  question: string;
  answer: string;
};

export type ArticleBoxType =
  | ({
      _type: 'textBlock';
    } & TextBlockType)
  | ({ _type: 'blockQuote' } & BlockQuoteBoxType)
  | ({ _type: 'infoBlock' } & InfoBoxType)
  | ({ _type: 'callToActionBlock' } & CallToActionBoxType)
  | ({ _type: 'cardBlock' } & CardsBoxType)
  | ({ _type: 'readAlsoBlock' } & ReadAlsoBoxType)
  | ({ _type: 'captionedImage' } & CaptionedImageBoxType)
  | ({ _type: 'textTable' } & TableBoxType)
  | ({ _type: 'sideNote' } & SideNoteType)
  | ({ _type: 'embeddedBox' } & EmbeddedBoxType)
  | ({ _type: 'buttonBox' } & ButtonBoxType)
  | ({ _type: 'snippetBox' } & SnippetBoxType)
  | ({ _type: 'excoBox' } & ExcoBoxType)
  | ({ _type: 'googleTrendsBox' } & GoogleTrendsBoxType)
  | ({ _type: 'tradingViewEmbedBox' } & TradingViewEmbedType)
  | ({ _type: 'checkliEmbedBox' } & CheckliEmbedBoxType);

export type PreviewArticleType = {
  title: string;
  slug: {
    current: string;
  };
  id: string;
  tags: Tag[];
  headerImage: {
    mainImage: {
      asset: any;
    };
    altText?: string;
    caption?: string;
  };
  faqs: ArticleFaqType[];
  boxes: ArticleBoxType[];
};

export type AuthorType = {
  name: string;
  title: string;
  image: {
    asset: any;
  };
  description?: string;
  linkedin?: string;
  facebook?: string;
  twitter?: string;
};

export interface ExpandedArticleType extends PreviewArticleType {
  metaTitle: string;
  author: AuthorType[];
  description: string;
  readAlsoArticles: PreviewArticleType[];
  contactUsButtonText: string;
  contactUsButtonColor: string;
  _createdAt: string;
  _updatedAt: string;
}

export type ArrayElement<
  ArrayType extends readonly unknown[]
> = ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export type SocialIconProps = {
  pathClassName: string;
  circleClassName: string;
  parentClassName: string;
};

export interface ArticleStructuredDataType
  extends Pick<ExpandedArticleType, 'title'> {
  authorName: string;
  imageUrl: string;
  slug: string;
  datePublished: string;
  dateModified: string;
  faqs: ArticleFaqType[];
}
