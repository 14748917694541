import * as React from 'react';
import { SocialIconProps } from '../../utils/blogTypes';

const SmallLinkedinIcon: React.FC<SocialIconProps> = ({
  pathClassName,
  circleClassName,
  parentClassName,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    className={parentClassName}
    {...props}
  >
    <circle
      cx={16}
      cy={16}
      r={15}
      fill="#fff"
      stroke="#D8D8D8"
      strokeWidth={2}
      className={circleClassName}
    />
    <path
      className={pathClassName}
      fill="#D8D8D8"
      fillRule="evenodd"
      d="M12.518 22.77v-9.516h-3.01v9.516h3.01Zm-1.506-10.816c1.05 0 1.702-.73 1.702-1.643-.02-.934-.653-1.645-1.682-1.645-1.03 0-1.702.71-1.702 1.645 0 .913.653 1.643 1.662 1.643h.02ZM14.663 22.718h3.058v-5.092c0-.273.02-.545.1-.74.221-.545.724-1.109 1.569-1.109 1.106 0 1.549.837 1.549 2.063v4.878h3.057V17.49c0-2.8-1.508-4.104-3.52-4.104-1.65 0-2.373.914-2.776 1.537h.02v-1.323h-3.057c.04.856 0 9.12 0 9.12Z"
      clipRule="evenodd"
    />
  </svg>
);
export default React.memo(SmallLinkedinIcon);
