import { sum } from 'ramda';
import { ArticleBoxType } from './blogTypes';

export const convertSnakeCaseToUppercase = (text: string) =>
  text
    .split('-')
    .join(' ')
    .toUpperCase();

export const AVERAGE_BOX_CHARACTER_COUNT = 100;

export const getBoxesReadTime = (boxes?: ArticleBoxType[]) => {
  const characterCount =
    sum(
      boxes?.map(box => {
        switch (box?._type) {
          case 'textBlock':
            return box?.text?.length;
          case 'infoBlock':
          case 'blockQuote':
            return box?.body?.length;
          default:
            return AVERAGE_BOX_CHARACTER_COUNT;
        }
      }) ?? []
    ) || 1;

  return Math.ceil(characterCount / 6 / 250); // characterCount / avg chars per word / avg word reading speed
};
