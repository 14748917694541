import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useCallback, useMemo } from 'react';
import { getBoxesReadTime } from '../../utils/textHelpers';
import { PreviewArticleType, Tag } from '../../utils/blogTypes';
import ArticleTag, { TagType } from '../ArticleTag';
import Link from '../Link';
import Text from '../Text';
import './style.scss';
import { useTranslation } from 'gatsby-plugin-react-i18next';

interface ArticlePreviewProps extends PreviewArticleType {
  className?: string;
}

const IMAGE_HEIGHT = 210;

const ArticlePreview: React.FC<ArticlePreviewProps> = ({
  title,
  headerImage: {
    mainImage: { asset },
    altText,
  },
  tags,
  className,
  slug,
  boxes,
}) => {
  const image = getImage(asset)!;

  const { t } = useTranslation();

  const renderTag = useCallback<(tag: Tag) => JSX.Element>((tag) => {
    return (
      <ArticleTag
        href={`/blog/tag/${tag}/`}
        tagValue={tag}
        tagType={TagType.AUTO}
        className="preview-footer__tag"
      />
    );
  }, []);

  const readTime = useMemo(() => getBoxesReadTime(boxes), [boxes]);

  const Image = useMemo(
    () =>
      image ? (
        <GatsbyImage
          image={image}
          alt={altText ?? ''}
          objectFit="cover"
          className="preview__image"
        />
      ) : (
        <img
          className="preview__image"
          alt={altText ?? ''}
          src={`${asset?.url}?h=${IMAGE_HEIGHT}`}
        />
      ),
    [altText, asset?.url, image]
  );

  return (
    <div className={`preview ${className}`}>
      <Link href={`/blog/${slug.current}/`}>
        {Image}
        <Text color="dark" type="p" weight="bold" className="preview__title h5">
          {title}
        </Text>
      </Link>
      <div className="preview-footer">
        {tags?.map(renderTag)}
        <Text
          type="p"
          weight="regular"
          color="dark"
          className="preview-footer__read-time"
        >
          {t('article_read_time', { time: readTime }).toUpperCase()}
        </Text>
      </div>
    </div>
  );
};

export default React.memo(ArticlePreview);
