import * as React from 'react';
import { SocialIconProps } from '../../utils/blogTypes';

const SmallFacebookIcon: React.FC<SocialIconProps> = ({
  pathClassName,
  circleClassName,
  parentClassName,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    className={parentClassName}
    {...props}
  >
    <circle
      cx={16}
      cy={16}
      r={15}
      fill="#fff"
      stroke="#D8D8D8"
      strokeWidth={2}
      className={circleClassName}
    />
    <path
      className={pathClassName}
      fill="#D8D8D8"
      fillRule="evenodd"
      d="m20 14.042-.313 2.757h-2.364v8H13.77v-8H12v-2.756h1.77v-1.66c0-2.243 1-3.583 3.844-3.583h2.365v2.757H18.5c-1.104 0-1.177.388-1.177 1.107v1.379H20Z"
      clipRule="evenodd"
    />
  </svg>
);
export default React.memo(SmallFacebookIcon);
