import * as React from 'react';
import { SocialIconProps } from '../../utils/blogTypes';

const SmallTwitterIcon: React.FC<SocialIconProps> = ({
  pathClassName,
  circleClassName,
  parentClassName,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    className={parentClassName}
    {...props}
  >
    <circle
      cx={16}
      cy={16}
      r={15}
      fill="#fff"
      stroke="#D8D8D8"
      strokeWidth={2}
      className={circleClassName}
    />
    <path
      className={pathClassName}
      fill="#D8D8D8"
      d="M24.128 13.173c.01.157.01.314.01.472 0 4.82-3.668 10.378-10.377 10.378v-.003a10.325 10.325 0 0 1-5.59-1.635 7.324 7.324 0 0 0 5.398-1.512 3.652 3.652 0 0 1-3.408-2.533c.546.106 1.11.084 1.646-.063a3.648 3.648 0 0 1-2.925-3.575v-.046c.507.282 1.074.44 1.655.457a3.652 3.652 0 0 1-1.129-4.87 10.352 10.352 0 0 0 7.517 3.81 3.65 3.65 0 0 1 6.216-3.327c.817-.16 1.6-.46 2.316-.885a3.662 3.662 0 0 1-1.604 2.017 7.255 7.255 0 0 0 2.095-.574 7.407 7.407 0 0 1-1.82 1.89Z"
    />
  </svg>
);
export default React.memo(SmallTwitterIcon);
